@import 'variables.scss';
@import 'mixins.scss';
@import 'navbar.scss';
@import 'buttons.scss';
@import 'about.scss';
@import 'projects.scss';
@import 'signup.scss';
@import 'contact.scss';
@import "../css/fontawesome-free/css/all.min.css";
//@import url("https://use.fontawesome.com/releases/v5.5.0/css/all.css");

body {
  //font-family: 'Nunito';

  //font-family: 'Secular One', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  //font-family: "FontAwesome";
  //font-family: $fa-font-path;
  //font-family: "Font Awesome 5 Free";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 0.0625em;
  color: #80858a;
  //background-color: $gray-800;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.build-nav-border {
  border-top: 1px solid $primary;
}

.build-nav-spacer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: 3px solid $primary !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.border-gray {
  border-color: $gray-200 !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}

.pager-border {
  border-top: 2px solid $primary;
}

.difficulty {
  display: flex;
  flex-flow: row wrap;
}

.difficulty > div {
  flex: 1;
  padding: 0.5rem;
}

input[type="radio"] {
  display: none;
}


/* Reset Select */
.select-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #FFF;
  background-image: none;
}

/* Remove IE arrow */
.select-dropdown::-ms-expand {
  display: none;
}
/* Custom Select */
.select-dropdown-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #FFF;
  overflow: hidden;
  border-radius: .25em;
}
.select-dropdown {
  flex: 1;
  padding: 0 .5em;
  color: $primary;
  cursor: pointer;
}
/* Arrow */
.select-dropdown-container::after {
  content: '\f107';
  font:  20px/1 FontAwesome;
  height: 100%;
  position: absolute;
  font-size: 25px;
  padding: 12px 15px 0px 15px;
  top: 0;
  right: 0;
  color: $primary;
  background: $secondary;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select-dropdown-container:hover::after {
  color: #FFF;
}


.small-radio-button-label {
  min-height: 125px;
  display: block;
  background: white;
  border: 2px solid $primary;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
  position: relative;
  max-width: 100%;
}
.small-radio-button-label p {
  padding: 10px;
}

.radio-button-label {
   min-height: 150px;
   display: block;
   background: white;
   border: 2px solid $primary;
   margin-bottom: 1rem;
   text-align: center;
   box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
   position: relative;
   max-width: 290px;
   margin: auto;
   min-height: 376px;
 }
.radio-button-label p {
  padding: 10px;
}

input[type="radio"]:checked + label {
  background: $primary;
  color: white;
}

.radio-button-label:hover {
  background: $primary;
  color: #dee2e6;
}

.radio-button:checked + label::after {
  color: $primary;
  border: 2px solid $primary;
  font-family: 'Font Awesome\ 5 Free';
  content: "\2714";
  font-size: 39px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 700px) {
  section {
    flex-direction: column;
  }
}


//REGULAR CHECKBOXES
ul.checkboxes {
  list-style: none;
  padding: 0px;
}
ul.checkboxes li{
  display: inline;
}
ul.checkboxes li label{
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .9);
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  border-radius: 0px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  margin-right: 10px;
  min-width: 209px;
}

ul.checkboxes li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

ul.checkboxes li input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

ul.checkboxes li input[type="checkbox"]:checked + label {
  border: 2px solid #1bdbf8;
  background-color: $primary;
  color: #fff;
  transition: all .2s;
}

ul.checkboxes li input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.simple-checkbox label{
  padding: 1px 6px;
  cursor: pointer;
  border: 2px solid rgba(139, 139, 139, .3);
  color: #adadad;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  margin-right: 10px;
  min-width: 100px;

  background-color: #c5c4c4;
  color: #FFFFFF;

  margin-left: auto;
  margin-right: auto;
  display: table;
  border-radius: 25px;
  text-align: center;

  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: .15rem;

}

.simple-checkbox label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

.simple-checkbox input[type="radio"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

.simple-checkbox input[type="radio"]:checked + label {
  border: 2px solid rgba(139, 139, 139, .3);
  background-color: $primary;
  color: #fff;
  transition: all .2s;
}

.simple-checkbox input[type="radio"] {
  position: absolute;
  opacity: 0;
}


.checkboxesWithImg .checkbox-img-container {
  display: inline-block;
}

.triangle {
  position: absolute;
  display: inline-block;
  margin: 0px 0px;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-top: solid 18px $primary;
  border-left: solid 18px $primary;
  border-bottom: solid 18px transparent;
  border-right: solid 18px transparent;
}

.grid-style {
  background-color: red;
  height: 200px;
  width: 300px;
}


//CHECKBOXES WITH IMAGES

.checkbox-container {
  display: inline;
}

.checkboxesWithImg {
  padding: 0px;
}
.checkboxesWithImg {
  display: inline;
}

.checkbox-img-container label{
  display: inline-block;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #FFFFFF;
  border-radius: 0px;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  margin-bottom: 0px;
}

.checkbox-img-container label::before {
  display: inline-block;
  position: absolute;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  padding: 3px 0px 0px 4px;
  content: "\f067";
  transition: transform .3s ease-in-out;
}

.checkbox-img-container input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform .3s ease-in-out;
}

.checkbox-img-container input[type="checkbox"]:checked + label {
  color: #fff;
  transition: all .2s;
}

.checkbox-img-checked {
  border: 5px solid $primary;
}


.checkbox-img-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.checkbox-img-container .checkbox-img-image {
  height: 150px;
  width: 112px;
}

.radio-button-img-container img {
  height: 150px;
  width: 150px;
}

.checkbox-img-container .checkbox-img-icon {
  height: 85px;
  width: 85px;
  margin-top: 21px;

}

.checkbox-icon-container {
  width: 100%;
}

.checkbox-icon-container label {
  width: 100%;
  border: 2px solid $primary;
}

.center-text {
  text-align: center;
}

.center-img {
  margin: 0 auto;
  display: table;
}

.radio-button-img-container  {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.cpu {
  background-color: #3daead;
}

.checkbox-img-container {
  display: table;
  margin-bottom: 20px;
}


.img-text {
  background-color: $primary;
  color: white;
  width: 113px;
  font-size: 11px;
  padding: 5px 5px;
  position: absolute;
  z-index: 100;
  display: none;
}

.icon-text{
  color: $primary;
  word-break: initial;
  white-space: normal;
  text-align: center;
}

.icon-text-wrapper {
  padding-left: 5px;
  padding-right: 5px;
 //max-width: 118px;
}

.checkbox-img-container:hover .img-text {
  display: block;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .checkbox-img-container img {
      height: 144px;
      width: 107px;
    }
  .sv-padding {
    padding-left: 15px !important;
  }
  .img-text {
    width: 75px;
  }
    .xs-screen {
      max-width: 24.2%;
    }
  .img-grid {
    padding: 0px;
    background-color: #FFFFFF;
  }
}

.main-form {
  min-height: 490px;
  background-color: $primary;
  padding: 25px;
  color: #FFFFFF;
  border-radius: 4px;
}


.card-text {
  font-size: 12px;
  margin-bottom: 1rem;
}

.card-container {
  padding: 2px 12px;
  background-color: $secondary;
  min-height: 110px;
}

.case-image {
  width: 100%;
  cursor: pointer;
}

.case-title{
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .case-name {
    min-height: 47px;
  }

  .card-container {
    min-height: 125px;
  }
}

.quick-details {
  display: flex;
  list-style: none;
  padding: 1.4em 0 1em;
  margin: 0;
  justify-content: space-between;
}

.quick-details-title {
  font-size: 12px;
}

.quick-details-item {
  flex: 1;
}
.quick-details-title {
  display: block;
}

.recipe-details .recipe-details-item .value {
  color: #ff4f87;
  margin-left: .35em;
  vertical-align: bottom;
  font-size: 24px;
  font-weight: 600;
}

.recipe-details .recipe-details-item .title {
  display: block;
  margin-top: -4px;
  font-family: var(--headlinesFont);
  font-size: 21px;
  font-weight: 300;
}

.case-card-format {
  border: 1px solid $primary;
}

.card-img-container {
  padding: 8px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  //background-color: #2196F3;
  //padding: 10px;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) ) ;
  grid-gap: 0px;
}

.service-item {
  padding: 3rem;
  text-align: center;
  border-top: 1px solid #dfdfdf;
}

.grid-item:nth-child(odd) {
  //border-right: 1px solid $primary;
}

.show-modal {
  display: block;
  padding-right: 17px;
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
}

.other-case-image {
  width: 83px;
  cursor: pointer;
}

.small-button {
  padding: .375rem .75rem !important;
}

.modal-dialog {
  max-width: 900px;
}

.details-table-left-col {
  background-color: $secondary;
  padding: 10px;
}
.details-table-right-col {
  padding: 10px;
  border: 2px solid $secondary;
}

.current-other-case-image {
  width: 450px;
}

.center-details-images {
  display: flex;
  align-items: center;
}

.other-image-holder {
  margin-bottom: 10px;
}

@media (max-width: 575.98px) {
  .current-other-case-image {
    width: 325px;
  }
  .other-case-image {
    width: 60px;
  }
  .details-table {
    font-size: 12px;
  }
}


.recommendation-price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.recommendation-price .recommendation-header {
  background-color: $primary;
  color: white;
  font-size: 25px;
}

.recommendation-price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.recommendation-price .grey {
  background-color: #eee;
  font-size: 25px;
}
.recommendation-details{
  min-height: 410px;
}

.recommendation-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .recommendation-columns {
    width: 100%;
  }
}

.simple-radio-btn {
  color: $primary;
  background-color: $secondary;
}

.simple-radio-btn-active {
  color: #ffffff;
  background-color: #cdd5deed;
}

/************************LOADER*************************/

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $primary;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*************TABS*****************/
.recommendation-header {
  text-transform: capitalize;
}
.tab-list-item{
  display: inline;

  background-color: $secondary;
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding: 8px;
  text-align: center;
}

.active-list-item {
  background-color: $primary;
  color: white;
  font-size: 20px;
  border-bottom: 1px solid #eee;
  padding: 8px;
  text-align: center;
}

.tab-link {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
}

.tab-link:hover {
  background-color: #777;
}

.show-tab {
  display: block;
}

.hide-tab {
  display: none;
}

.tab-body-icon {
  height: 50px;
  margin-right: 10px;
  fill: #ffffff;
}

.tab-body-icon path {
  fill: #FFFFFF;
}

.component-image {
  width: 65px;
  margin-right: 15px;
}

.outline-btn {
  border: 4px solid #FFFFFF;
  background-color: $primary;
  color: $primary;
  padding: 14px 14px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.outline-btn-primary {
  border-color: #ffffff;
  color: #ffffff;
}

.outline-btn-selected{
  background-color: $secondary;
  color: $primary;
}

.component-list-details {
  //font-size: 12px;
}

.configuration-summary-header {
  background-color: #3498db;
  color: white;
  font-size: 25px;
  border-bottom: 1px solid #eee;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;

}

.config-case-image {
  //width: 420px;
  opacity: 0.2;
  //cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.configuration-details-list {
  list-style-type: none;
  //border: 1px solid #eee;
  margin: 0;
  //padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.configuration-details-list li {
  //border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: left;
}

.configuration-summary-price {
  background-color: #eee;
  font-size: 25px;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}

.configuration-details {
  min-height: 368px;
}

.components-list-header {
  background-color: #eee;
  color: $primary;
  font-size: 25px;
  //border-bottom: 1px solid #eee;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 5px;
}

.component-list-btn {
  width: 86px;
}

.component-list {
  min-height: 313px;
}

.configuration-details-list-container {
  position: absolute;
}
.config-details-text {
  font-weight: bold;
}

.active-component-tab {
  background-color: $tertiary;
  color: #FFFFFF;
  text-transform: capitalize;
}

.inactive-component-tab {
  background-color: #eeeeee;
  color: $tertiary;
  text-transform: capitalize;
}

.active-component-tab:hover{
  color: #FFFFFF !important;
}

.inactive-component-tab:hover{
  color: $tertiary !important;
}

.active-component-tab:focus{
  color: #FFFFFF !important;
}

.inactive-component-tab:focus{
  color: $tertiary !important;
}

.component-category-tab:last-child {
  margin-left: 5px;
}
.component-category-tab:first-child {
  margin-right: 5px;
}

.component-quantity-dropdown {
  width: 85px;
}

.component-quantity-container {
  margin-right: 10px;
}


/* Reset Select */
.select-dropdown-component-quantity {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  //border: 0 !important;
  border: 4px solid #ffffff;
  background: $primary;
  background-image: none;
}

/* Remove IE arrow */
.select-dropdown-component-quantity::-ms-expand {
  display: none;
}
/* Custom Select */
.select-dropdown-container-component-quantity {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  line-height: 3;
  background: #FFF;
  overflow: hidden;
  border-radius: .25em;
}
.select-dropdown-component-quantity {
  flex: 1;
  //padding: 0 .5em;
  padding: 5px 18px;
  color: #FFFFFF;
  cursor: pointer;
}

.component-quantity-dropdown > option {
  background-color: #FFFFFF;
  color: $primary;
}

/* Arrow */
.select-dropdown-container-component-quantity::after {
  content: '\f107';
  font:  20px/1 FontAwesome;
  height: 100%;
  position: absolute;
  font-size: 25px;
  padding: 20px 10px 0px 10px;
  top: 0;
  right: 0;
  color: $primary;
  background: $secondary;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select-dropdown-container-component-quantity:hover::after {
  color: #FFF;
}

.disable-quantity-dropdown {
  opacity: 0.5;
}

//.disable-quantity-dropdown:hover {
//  color: $primary;
//}

.disable-quantity-dropdown:hover::after {
  color: $primary;
}

.component-list-details {
  font-size: 12px;
}

.component-list li {
  margin-bottom: 5px;
}