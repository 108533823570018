
.input-format{
  height: 55px;
  font-size: 25px;
}

.text-format{
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
}

.select-format {
  height: 55px;
  font-size: 25px;
}


.select {
  position: relative;
  /*Don't really need this just for demo styling*/

  float: left;
  min-width: 200px;
  /*margin: 50px 33%;*/
}


ul {
  list-style: none;
}

.cf:before, .cf:after {
  content: ' ';
  display: table;
}
.cf:after {
  clear: both;
}

.title {
  padding: 50px 0;
  font: 24px 'Open Sans', sans-serif;
  text-align: center;
}

.inner {
  /*max-width: 820px;*/
  /*margin: 0 auto;*/
}

.breadcrumbs {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  border-radius: 4px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.breadcrumbs li {
  float: left;
  width: 20%;
}

.breadcrumbs a {
  position: relative;
  display: block;
  padding: 20px;
  padding-right: 0 !important;
  /* important overrides media queries */
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #aaa;
  /*cursor: pointer;*/
  text-transform: capitalize;
}


.breadcrumbs a.active {
  color: #777;
  background-color: #fafafa;
}

.hide-icon {
  color: #FFFFFF;
}

.breadcrumbs a span:first-child {
  display: inline-block;
  width: 22px;
  height: 22px;
  /*padding: 2px;*/
  margin-right: 5px;
  border: 2px solid #aaa;
  border-radius: 50%;
  background-color: #fff;
}

.breadcrumbs a.active span:first-child {
  color: #fff;
  border-color: #777;
  background-color: #777;
}

.breadcrumbs a:before,
.breadcrumbs a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: block;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 16px solid transparent;
}

.breadcrumbs a:before {
  margin-left: 1px;
  border-left-color: #d5d5d5;
}

.active-step {
  color: #4d8ff8 !important;
}
.active-step i {
  color: #4d8ff8 !important;
}

.active-step span {
  border-color: #4d8ff8 !important;
}

.completed-step {
  color: #189c49 !important;
}
.completed-step i {
  color: #189c49 !important;
}

.completed-step span {
  border-color: #189c49 !important;
}

.breadcrumbs a:after {
  border-left-color: #f5f5f5;
}

.breadcrumbs a.active:after {
  border-left-color: #fafafa;
}

.breadcrumbs li:last-child a:before,
.breadcrumbs li:last-child a:after {
  display: none;
}

@media (max-width: 720px) {
  .breadcrumbs a {
    padding: 15px;
  }

  .breadcrumbs a:before,
  .breadcrumbs a:after {
    border-top-width: 26px;
    border-bottom-width: 26px;
    border-left-width: 13px;
  }
}
@media (max-width: 620px) {
  .breadcrumbs a {
    padding: 10px;
    font-size: 12px;
  }

  .breadcrumbs a:before,
  .breadcrumbs a:after {
    border-top-width: 22px;
    border-bottom-width: 22px;
    border-left-width: 11px;
  }
}
@media (max-width: 520px) {
  .breadcrumbs a {
    padding: 5px;
  }

  .breadcrumbs a:before,
  .breadcrumbs a:after {
    border-top-width: 16px;
    border-bottom-width: 16px;
    border-left-width: 8px;
  }

  .breadcrumbs li a span:first-child {
    display: block;
    margin: 0 auto;
  }

  .breadcrumbs li a span:last-child {
    display: none;
  }
}

/*FORM STYLING*/

.button-spacing {
  margin-left: 10px;
}

.form-actions {
  position: absolute;
  bottom: 21px;
  right: 70px;
}

.build-nav {
  /*padding-top: 50px;*/
  padding-bottom: 50px;
}
.build-form {
  background-color: #FFFFFF;
  height: 800px;
  width: 100%;
}

.build-container {
  padding-top: 50px;
  /*padding-bottom: 50px;*/
  background-repeat: no-repeat;
  background-size: cover;
}

.start-build-section {
  height: 900px;
}

.logo {
  height: 60px;
}

.main-build-section {
  /*background-color: #ced4da82;*/
  height: 800px;
  /* background-image: url('../img/circuit-bkg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
}

.top-spacer{
  padding-top: 100px;

}
.fs-20 {
  font-size: 20px;
}

.flex-container {
  display: flex;
}

.main-selection{
  height: 600px;
  width: 100%;
}

.info-section {
  height: 600px;
  width: 200px;
}

.form-styling {
  box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
  padding: 1.25rem 2rem;
  height: auto;
  /*font-family: 'Varela Round';*/
  font-size: 80%;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  border: 0;
}


#pc-building-video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100
}

.slider-bg {
  fill: #000000;
}
/*NAv-BAR*/
.nav-bar-gray {
  background-color: #495057 !important;
  opacity: 0.7;
}

.parallax {
  /* The image used */
  /*background-image: url('../img/mb5.png'); */

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  /*margin-bottom: 4rem;*/
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 43rem;
}
.carousel-item > img {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: 32rem;
  object-fit: cover;
  width: 100%;
  object-position: right center;
}

.carousel-item {
  position: relative;
  display: block !important;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /*transition: -webkit-transform 0.6s ease-in-out;*/
  /*transition: transform 0.6s ease-in-out;*/
  /*transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;*/

  visibility: hidden;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
  opacity: 0;
}


.carousel-item.active {
  opacity: 1;
  visibility: visible;
}

blockquote {
  font-size: 18px;
  font-style: italic;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #383838;
}

blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 100px;
  position: absolute;
  left: -20px;
  top: -20px;
  color: #7a7a7a;
}


blockquote cite {
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

blockquote cite:before {
  content: "\2014 \2009";
}

.about-icons {
  height: 120px;
  margin-bottom: 20px;
}

.no-padding-left {
  padding-left: 0px;
}

.no-padding-right {
  padding-right: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.no-margin-left {
  margin-left: 0px !important;
}

.no-margin-right {
  margin-right: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

no-margin {
  margin: 0px !important;
}

.btn-link-format {
  box-shadow: none !important;
  letter-spacing: 0.1rem ;
  text-transform: none;
  padding: .375rem .75rem;
}

/*****************PAGER*************/

.pagination a { cursor: pointer; }

.page-tracker {
  /*margin-left: 5px;*/
  border-left: 2px #ffffff solid;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  /*margin: 20px 0;*/
  border-radius: 4px
}

.pagination>li {
  display: inline
}

.pagination>li>a,.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd
}

.pagination>li:first-child>a,.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.pagination>li:last-child>a,.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:focus,.pagination>li>span:hover {
  z-index: 3;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd
}

.pagination>.active>a,.pagination>.active>a:focus,.pagination>.active>a:hover,.pagination>.active>span,.pagination>.active>span:focus,.pagination>.active>span:hover {
  z-index: 2;
  color: #fff !important;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7
}

.pagination>.disabled>a,.pagination>.disabled>a:focus,.pagination>.disabled>a:hover,.pagination>.disabled>span,.pagination>.disabled>span:focus,.pagination>.disabled>span:hover {
  color: #777 !important;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd
}

.pagination-lg>li>a,.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333
}

.pagination-lg>li:first-child>a,.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px
}

.pagination-lg>li:last-child>a,.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px
}

.pagination-sm>li>a,.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5
}

.pagination-sm>li:first-child>a,.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.pagination-sm>li:last-child>a,.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px
}

.pager {
  padding-left: 0;
  text-align: center;
  list-style: none
}

.pager li {
  display: inline
}

.pager li>a,.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  color: #777 !important;
  background-color: #fff;
  border: 1px solid #ddd;
  /*border-radius: 15px*/
}

.pager li>a:focus,.pager li>a:hover {
  text-decoration: none;
  background-color: #eee
}

.pager .next>a,.pager .next>span {
  float: right
}

.pager .previous>a,.pager .previous>span {
  float: left
}

.pager .disabled>a,.pager .disabled>a:focus,.pager .disabled>a:hover,.pager .disabled>span {
  color: #777 !important;
  cursor: not-allowed;
  background-color: #fff
}

.paginated-items {
  min-height: 540px;
}

.build-place-holder {
  padding-top: 90px;
}

.simple-checkbox-recommendation-page label{
  text-transform: capitalize !important;
}

.no-border {
  border: 0px !important;
}