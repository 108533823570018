// Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #161616 !default;
$orange: #ff6600 !default;

$fa-font-path: '../css/fontawesome-free/webfonts/fa-brands-400.ttf';


//$primary: #199EB8 !default;
//$primary: #3daead !default;
$secondary: #f1f1f1 !default;

$primary: #3498db !default;

$tertiary: #007bff !default;

$primary-rgba-4: rgba(52, 152, 219, 0.6) !default;

//$primary: #FF6600 !default;
//$primary: #64a19d !default;


